import * as React from "react"

const Button = (props) => {
  return (

    <a href={props.href} className={`text-center font-bold py-3 px-8 bg-gold text-black hover:bg-prussian hover:text-white text-lg block ${props.className}`} onClick={props.click}>
      {props.children}
    </a>
  )
}

export default Button