import * as React from "react"
import * as styles from "./tape.module.scss"

const Tape = (props) => {

  const direction = props.direction == "left" ? styles.left : styles.right
  const background = props.color == 'gold' ? 'bg-gold' : 'bg-prussian'
  return (

    <div className={`${background} py-1`}>
        <div className={`${styles.tape} ${direction} opacity-${props.opacity}`}>
            {
            Array.apply(null, { length: 20 }).map((e, i) => (
                <p className="mx-4 font-black text-white" key={i}>
                    <span className={i%2 == 0 ? 'text-outline' : ''}> {props.text} </span>
                </p>
            ))
            }
        </div>
    </div>

  )
}

export default Tape