import React, { useState, useEffect, useRef } from "react"
import Logo from "@components/logo"

const Footer = (props) => {

  const [isVisible, setIsVisible] = useState(false)
  const footerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
      
        setIsVisible(true)
        observer.unobserve(footerRef.current);
      }
    });
    
    observer.observe(footerRef.current);
  }, []);

  return (

    <div className="p-5 text-prussian bg-gold w-full" ref={footerRef}>
      <div className="page-size flex flex-col md:flex-row items-center justify-between">
          <a href="https://otl.notion.site/USTA-FAQ-9d1711d846c242f5b9e42253ac4600bf" target="_blank" className={`py-2 md:py-4 text-lg font-bold inline-block transition-opacity delay-300 duration-900 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            FAQ
          </a>
          <a href="https://bitski.com" target="_blank" className={`order-first md:order-none py-4 flex max-w-sm items-center transition-opacity delay-300 duration-900 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
          <p className="text-tiny mr-4">POWERED BY</p>
            <Logo />
          </a>
          <a href="https://otl.notion.site/USTA-NFT-Terms-Conditions-9486b1e6e8084dca9d90ed575d6f7f57" target="_blank" className={`py-2 md:py-4 text-lg font-bold inline-block transition-opacity delay-300 duration-900 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            T&C
          </a>
      </div>
         
    </div>
    
  )
}

export default Footer
