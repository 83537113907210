import * as React from "react"
import Helmet from 'react-helmet'

const Layout = (props) => {
  return (

    <div>
      <Helmet title="NFTS US Open - Powered by Bitski" defer={false}>
       <script type="module" src="https://cdn.bitskistatic.com/bitski-ui/latest/dist/bitski-ui/bitski-ui.esm.js"></script>
      </Helmet>
      {props.children}
    </div>
  )
}

export default Layout