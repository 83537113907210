import * as React from "react"

const USOpen = (props) => {


  return (

    <svg width="257" height="35" viewBox="0 0 257 42" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path d="M23.0122 32.7024C52.3827 36.9374 54.2741 39.7432 61.6023 39.7432C67.2942 39.7432 71.9823 37.9932 75.5274 35.0241C64.4569 32.0899 40.8132 31.4657 23.0122 32.7024Z" fill="white"/>
    <path d="M80.1334 10.1033C52.7817 8.06674 25.3361 12.5061 0 23.065C29.8926 24.8617 52.2193 27.4458 77.7023 32.8942C80.4132 29.8205 82.1041 25.9739 82.5398 21.8893C82.9755 17.8047 82.1344 13.6851 80.1334 10.1033Z" fill="white"/>
    <path d="M62.1751 0C47.0895 0 27.4667 5.635 12.3115 11.2758C33.0019 7.245 58.6938 6.20667 78.4211 7.53667C75.433 3.91417 70.7391 0 62.1751 0Z" fill="white"/>
    <path d="M121.363 25.6773C123.55 26.7877 125.947 27.4199 128.395 27.5323C130.576 27.5323 131.766 26.7506 131.917 25.584C132.068 24.4173 131.336 23.589 129.062 22.5156C126.202 21.1506 123.95 19.1556 124.333 16.1865C124.972 11.2631 130.135 8.0198 136.848 8.0198C138.933 7.93054 141.014 8.25973 142.97 8.98813L141.328 14.3373C139.616 13.5593 137.764 13.1424 135.885 13.1123C133.86 13.1123 132.369 13.9406 132.207 15.2065C132.079 16.1806 133.036 17.0031 135.05 17.9306C137.707 19.249 140.446 21.1448 140.016 24.4581C139.314 29.9123 133.872 32.8348 126.996 32.8348C124.465 32.911 121.95 32.3874 119.657 31.3065L121.363 25.6773Z" fill="white"/>
    <path d="M164.113 8.00317C157.324 8.00317 150.391 13.5273 148.279 20.3523C146.167 27.1773 149.573 32.8415 156.361 32.8415C163.863 32.8415 170.472 27.1832 172.607 20.3582C174.742 13.5332 170.901 8.00317 164.113 8.00317ZM165.598 17.844C165.123 21.9273 162.332 27.5332 158.502 27.5332C156.083 27.5332 154.94 25.684 155.265 22.9073C155.653 19.2032 158.38 13.3115 162.401 13.3115C165.198 13.3115 165.848 15.6507 165.598 17.844Z" fill="white"/>
    <path d="M168.333 41.9999L175.736 17.7391C176.595 14.9158 177.691 10.9666 178.266 8.5341H184.55L183.662 12.2091H183.76C185.959 9.5491 188.982 7.99744 191.849 7.99744C196.983 7.99744 198.968 12.1391 198.393 17.0041C197.424 25.2874 191.025 32.8358 182.791 32.8358C181.301 32.8844 179.823 32.5491 178.498 31.8616H178.417L175.312 41.9941L168.333 41.9999ZM180.105 26.3549C180.507 26.7526 180.987 27.0617 181.515 27.2626C182.042 27.4634 182.605 27.5517 183.169 27.5216C186.899 27.5216 190.787 21.8749 191.338 17.1966C191.558 15.3008 191.019 13.2999 188.744 13.2999C186.128 13.2999 183.035 16.6133 181.614 21.2858L180.105 26.3549Z" fill="white"/>
    <path d="M216.398 31.3132C213.801 32.3691 211.019 32.8889 208.217 32.8415C201.434 32.8415 198.4 28.9973 199.125 22.8082C199.972 15.5515 206.088 8.00317 214.669 8.00317C219.462 8.00317 222.589 10.6807 222.067 15.114C221.353 21.204 215.238 23.3915 205.769 23.1465C205.676 23.9851 205.829 24.8326 206.21 25.5848C206.987 26.804 208.53 27.5332 210.544 27.5332C213.165 27.4249 215.739 26.7944 218.116 25.6782L216.398 31.3132ZM213.178 13.1132C209.929 13.1132 207.776 15.7965 207.028 18.0365C212.453 18.0832 215.255 17.3073 215.505 15.1607C215.667 13.9473 214.797 13.1132 213.178 13.1132Z" fill="white"/>
    <path d="M220.102 32.3225L224.982 16.2983C225.87 13.2708 226.548 10.6458 227.071 8.55747H233.349L232.467 12.2325H232.559C233.603 10.8936 234.94 9.81601 236.468 9.08426C237.995 8.35251 239.67 7.98651 241.361 8.01497C245.237 8.01497 247.134 10.4066 246.653 14.4958C246.457 15.8612 246.17 17.2118 245.794 18.5383L241.564 32.3225H234.602C234.602 32.3225 237.77 22.0033 238.663 19.0283C239.696 15.6391 239.719 13.3291 237.155 13.3291C234.729 13.3291 232.095 15.96 230.447 21.35C228.8 26.74 227.1 32.3225 227.1 32.3225H220.102Z" fill="white"/>
    <path d="M123.456 8.53467L118.599 24.5647C117.712 27.5863 117.033 30.2113 116.511 32.3055H110.209L111.091 28.6305H110.998C109.954 29.9676 108.616 31.0436 107.089 31.7743C105.562 32.5049 103.887 32.8704 102.197 32.8422C98.3208 32.8422 96.4235 30.4563 96.9051 26.3672C97.1005 25.0018 97.3874 23.6512 97.7638 22.3247L101.994 8.53467H108.956C108.956 8.53467 105.788 18.8597 104.895 21.8347C103.862 25.218 103.839 27.5338 106.403 27.5338C108.828 27.5338 111.463 24.8972 113.11 19.5072C114.758 14.1172 116.47 8.53467 116.47 8.53467H123.456Z" fill="white"/>
    <path d="M256.995 4.77841C256.995 5.52141 256.775 6.24772 256.365 6.8655C255.954 7.48328 255.371 7.96479 254.688 8.24912C254.005 8.53345 253.254 8.60785 252.529 8.46289C251.804 8.31794 251.138 7.96016 250.616 7.43478C250.093 6.9094 249.737 6.24002 249.593 5.5113C249.449 4.78258 249.523 4.02724 249.806 3.3408C250.089 2.65436 250.568 2.06765 251.182 1.65486C251.797 1.24207 252.519 1.02175 253.258 1.02175C253.754 1.00292 254.248 1.08721 254.71 1.26934C255.172 1.45147 255.591 1.72751 255.942 2.08017C256.293 2.43284 256.567 2.85452 256.748 3.31882C256.93 3.78312 257.013 4.28003 256.995 4.77841ZM256.49 4.77841C256.484 4.13706 256.29 3.51176 255.931 2.98129C255.573 2.45082 255.066 2.03891 254.475 1.79745C253.884 1.55599 253.235 1.4958 252.61 1.62444C251.985 1.75309 251.412 2.06482 250.963 2.52036C250.514 2.97591 250.209 3.55488 250.086 4.18435C249.964 4.81382 250.03 5.46561 250.275 6.05762C250.521 6.64963 250.935 7.15537 251.466 7.51112C251.996 7.86687 252.62 8.05672 253.258 8.05675C253.688 8.06988 254.117 7.99384 254.517 7.83336C254.917 7.67287 255.28 7.43138 255.583 7.12388C255.886 6.81639 256.123 6.44948 256.279 6.04598C256.435 5.64248 256.507 5.21101 256.49 4.77841ZM254.917 3.83341C254.922 4.11051 254.828 4.38007 254.652 4.59384C254.477 4.80761 254.232 4.95162 253.96 5.00008L254.883 6.78508H254.244L253.351 5.03508H252.463V6.78508H251.912V2.63175H253.589C253.758 2.60672 253.93 2.61946 254.093 2.66903C254.257 2.71861 254.407 2.80382 254.534 2.91858C254.661 3.03334 254.761 3.17482 254.827 3.33295C254.894 3.49108 254.924 3.66196 254.917 3.83341ZM254.366 3.83341C254.366 3.36091 254.076 3.12175 253.56 3.12175H252.463V4.52758H253.496C254.088 4.52758 254.372 4.32341 254.372 3.83341H254.366Z" fill="white"/>
    </svg>
    
  )
}

export default USOpen