import React, { useState, useEffect, useRef } from "react"
import * as styles from './hero.module.scss'
import { StaticImage } from "gatsby-plugin-image"
import Nav from "@components/nav"
import Button from "@components/button"
import Orbe from "@components/orbe"

function scrollTop(e) {
  e.preventDefault();
  const offsetTop = document.querySelector("#main").offsetTop+50;
 
  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}

const Hero = () => {

  const [isVisible, setIsVisible] = useState(false)
  const heroRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
      
        setIsVisible(true)
        observer.unobserve(heroRef.current);
      }
    });
    
    observer.observe(heroRef.current);
  }, []);

  return (

    <div className={`${styles.hero} w-fulls bg-no-repeat bg-cover`} ref={heroRef}>
      <div className={`page-size relative h-full transition-opacity delay-300 duration-900 ${isVisible ? 'opacity-100' : 'opacity-0'}`} >
        <Nav />
        <div className={`absolute top-0 hidden max-w-md h-full md:block`}>
          <StaticImage
            className="max-h-full h-full"
            src="../../assets/images/hero_foreground.png"
            placeholder="none"
            alt=""
          />
        </div>     

        <div className="flex items-center lg:items-start text-white w-full px-10 py-4 flex-col lg:flex-row transition-opacity">
          <div className={`${styles.textColumn} relative flex xl:items-center`}>
            <div className={`${styles.content}  2xl:absolute`}>
              <h1 className="font-black leading-none"><span className="text-outline block">US OPEN <br/> CHAMPION CARDS</span> 2021 EDITION</h1>
              <div className="">
                <h3 className="font-light mt-6 mb-5 lg:text-xl">Limited edition NFT trading cards featuring legendary US Open Champions</h3>
                <div className={`md:flex items-center`}>
                  <Button className="mr-6 mb-4 md:mb-0 md:inline-block cursor-pointer" click={scrollTop}>Shop NFTs</Button>
                  <div className="font-bold text-lg text-center flex items-center">Live Drop in Progress <Orbe /></div>
                </div>
              </div>
            </div>
          </div>

          <div className={`relative max-w-sm py-10 lg:mr-20 md:py-0 hidden lg:block`}>
            <StaticImage
                src="../../assets/images/cards.png"
                placeholder="none"
                alt=""
                quality={100}
              />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero