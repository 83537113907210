import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./nav.module.scss"
import USOpen from '@components/logo/usopen'

const Nav = (props) => {
  return (

    <nav className={`w-full px-10 py-8 text-center ${styles.nav}`}>
        <USOpen className={`md:float-right mx-auto ${styles.logo}`} />
    </nav>
  )
}

export default Nav