import * as React from "react"
import * as styles from "./marquee.module.scss"
import Tape from "./tape.js"

const Marquee = (props) => {
  return (

    <div className={`w-full overflow-hidden relative`}>

        <div className={` ${styles.transformer}`}>
            <Tape text={"US OPEN 2021"} direction="left" opacity={"20"}/>
        </div>
        <div className={` ${styles.transformer} -mt-1`}>
            <Tape text={"US OPEN 2021"} color={"gold"} direction="right" opacity={"60"}/>
        </div>
        

       
    </div>


  )
}

export default Marquee