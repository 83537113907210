import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Orbe = (props) => {
  return (
    <span className={"inline-flex pl-1 pt-1"}>
       <StaticImage
            className="animate-pulse !align-middle"
            src="../../assets/images/orbe.png"
            placeholder="none"
            alt=""
          />
    </span>
 
  )
}

export default Orbe